<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div class="width-480 ma-auto bg-white br-20 pa-40-30 bg-white position-relative" style="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);">
				<div class="flex-row justify-center mb-20">
					<h3 class="focus size-px-20">인출 내역</h3>
					<div class="popup-close">
                        <img class="cursor-pointer" @click="$emit('cancel')" :src="require('@/assets/image/web/notify_cartel/ico_close_bk.svg')" />
                    </div>
				</div>

				<div class="overflow-y-auto">
					<table class="table">
						<colgroup>
							<col style="width: 33.3%">
							<col style="width: 33.3%">
							<col style="width: 33.3%">
						</colgroup>
						<thead>
						<tr>
							<!-- 요청일 -->
							<th class="t-left">요청일</th>
							<!-- 완료일 -->
							<th class="t-right">완료일</th>
							<th class="t-right">수량</th>
						</tr>
						</thead>
						<tbody>
						<template
							v-if="items.length > 0"
						>
							<template
								v-for="(item, index) in items"
							>
								<tr
									:key="'item_' + index"
								>
									<!-- 요청일자 -->
									<td>{{ item.wthdwl_requestdate | transDate}}</td>
									<!-- 완료일자 -->
									<td class="">{{ item.wthdwl_compdt | transDate}}</td>
									<td class="">
										{{  item.wthdwl_coin_token_quantity | makeComma(8)}}
									</td>
								</tr>
							</template>
						</template>
						<tr
							v-else
						>
							<td colspan="3" class="td_list_none">
								<div class="list_none flex-column align-center gap-10">
									<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
									<!-- 이력이 없습니다 -->
									<span class="size-px-14 color-bbb">인출 내역이 없습니다</span>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>

				<Pagination
					:options="item_search"

					@click="getSearch"
					class="pa-20"
				></Pagination>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
import Pagination from "@/components/Pagination";

export default {
	name: 'mafia0193'
	,
	components: {PopupLayer, Pagination},
	props: ['user']
	, data: function(){
		return {
			program: {
				name: 'STAKING 인출 내역'
			}
			, items: []
			, item_search: {
				page_number: 1
				, pagerecnum: 10
				, page_cnt: 10
			}
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_staking_reward
					, data: {
						member_number: this.user.member_number
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})
				if(result.success){
					this.items = result.data.reward_wthdwl_particulars_list
					this.$set(this.item_search, 'total_count', result.data.reward_wthdwl_particulars_list.length)
				}else{
					this.$bus.$emit('notify',  { type: 'error', messages: result.message})
				}
			}catch (e) {
				this.$log.console(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(page){
			if(page){
				this.item_search.page_number = page
			}

			this.getData()
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>