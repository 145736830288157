<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div class="width-400 ma-auto radius-20 pa-40-30 bg-white position-relative" style="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);">
				<div class="flex-row justify-center">
					<h4 class="">MAFIA STAKING 보상 인출</h4>
					<div class="popup-close">
                        <img class="cursor-pointer" @click="$emit('cancel')" :src="require('@/assets/image/web/notify_cartel/ico_close_bk.svg')" />
                    </div>
				</div>
				<div class="mt-20">
					<div>인출 가능 수량</div>
					<div class="mt-16"><input
						v-model="item.wthdwl_possible_coin_token_quantity"
						type="number"
						class="input-box radius-20 pa-10-20 text-right"
						readonly
					></div>
					<div class="mt-20">인출 수량</div>
					<div class="mt-16 flex-row">
						<div class="flex-1 mr-10">
							<input
								v-model="item.wthdwl_request_coin_token_quantity"
								type="number"
								class="input-box radius-20 pa-10-20 text-right"
								placeholder="인출 수량을 입력하세요"
								:rules="[$rules.decimal(item, 'wthdwl_request_coin_token_quantity', { min:8, max: 8})]"
							/>
						</div>
						<button
							class="btn-inline btn-primary-outline radius-20 pa-10-30"
							@click="toMax"
						>전액</button>
					</div>

					<div class="mt-30 text-center">
						<button
							class="btn-inline btn-primary radius-20 pa-10-30 font-weight-500"
							:disabled="is_widthdrawal"
							@click="onPin"
						>보상인출</button>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
	import PopupLayer from "@/view/Layout/PopupLayer";
	export default {
		name: 'mafia0361'
		, props: ['user']
		, components: {PopupLayer}
		, data: function(){
			return {
				item: {
					wthdwl_possible_coin_token_quantity: 0
					, wthdwl_request_coin_token_quantity: 0
				}
			}
		}
		, computed: {
			is_widthdrawal: function(){
				let t = true

				if(Number(this.item.wthdwl_possible_coin_token_quantity) > 0 && Number(this.item.wthdwl_possible_coin_token_quantity) >= Number(this.item.wthdwl_request_coin_token_quantity)){
					t = false
				}
				return t
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_staking_reward_info
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})
					if(result.success){
						this.item = result.data
					}else{
						throw result.message
					}
				}catch (e){
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postReward: async function(pin_number){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_staking_reward
						, data: {
							member_number: this.user.member_number
							, pinnumber: pin_number
							, wthdwl_request_coin_token_quantity: this.item.wthdwl_request_coin_token_quantity
						}
						, type: true
					})
					if(result.success){
						this.item = result.data
					}else{
						throw result.message
					}
				}catch (e){
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.$bus.$off('pinCallback')
					this.$emit('cancel')
				}
			}
			, toMax: function(){
				this.$set(this.item, 'wthdwl_request_coin_token_quantity', this.item.wthdwl_possible_coin_token_quantity)
			}

			, onPin: function(){
				this.$bus.$emit('onPin', 'check')
				this.$bus.$on('pinCallback', (type, pin_number) => {
					if(type == 'check'){
						this.postReward(pin_number)
					}
					this.$bus.$off('pinCallback')
				})
			}

		}
		, created() {
			this.getData()
		}
	}
</script>